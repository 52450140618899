<template>
  <div v-if="event" class="single-page">
    <h2>{{ event.name }}</h2>
    <InSupport />
    <ThresholdReached v-if="event.isHomeLottery && event.thresholdReached"/>
    <div v-else class="ticket-packages">
      <h4 class="text-regular mb-3">{{ $t('ticketPackages') }}</h4>
      <TicketPackage
        v-for="ticketPackage of event.ticketPackages"
        :key="ticketPackage.series"
        :ticket-package="ticketPackage"
        :event-id="event.id"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import InSupport from '@/components/InSupport.vue';
import TicketPackage from '@/components/TicketPackage.vue';
import ThresholdReached from '@/components/ThresholdReached.vue';
import checkout from '@/model/checkout';
import { eventMemberCheck } from '@/lib/helpers';
import { Event } from '@/lib/schema/event.schema';
import EventMemberServiceV2 from '@/lib/services/event-member-service-v2';

@Component({ components: { InSupport, TicketPackage, ThresholdReached } })
export default class Single extends Vue {
  event: Event | null = null;
  eventMemberNumber = -1;

  created() {
    // On back button force page refresh.
    window.onpopstate = function () {
      location.reload();
    };
  }

  mounted() {
    this.event = this.$store.state.event;

    if (this.$route.query.eventMemberNumber) {
      checkout.selectedEventMember = eventMemberCheck(this.$route.query.eventMemberNumber as string);
    }
    this.eventMemberNumber = checkout.selectedEventMember ?? -1;

    if (this.eventMemberNumber !== -1) {
      this.fetchEventMembers();
    }
  }

  async fetchEventMembers() {
    if (this.event) {
      const selectedMember = await EventMemberServiceV2.getSelectedEventMember(
        this.eventMemberNumber,
        this.event.number
      );

      if (selectedMember) {
        if (selectedMember.groupName !== undefined) {
          checkout.selectedEventMemberName = `${selectedMember.groupName}, ${selectedMember.memberName}`;
        } else {
          checkout.selectedEventMemberName = `${selectedMember.memberName}`;
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.single-page {
  h2 {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }
}

.in-support-of {
  margin-bottom: 1.5rem;

  .required {
    color: $dangerRed;
  }
}
</style>
